<template>
    <ContentWrapper>
        <breadcrumb icon="fad fa-blanket" :name="$t('wallet.view.action.make_deposit.heading')" />

        <div class="container animated-fast fadeInDown">
            <div class="card card-default" :class="loading ? 'whirl' : ''">
                <div class="card-header">
                    {{$t('wallet.view.action.make_deposit.heading')}}
                </div>

                <div class="card-body">

                    <div class="text-center">
                        <span class="" style="font-size:25px">{{$t('wallet.view.action.make_deposit.amount')}}</span><br/>
                        <div class="mt-3">
                            <b-input class="text-center bg-transparent p-0 d-inline-block wd-sm text-right" style="font-size: 30px" v-model="deposit.amount" @change="validateAmount"></b-input>
                            <span class="ml-3" style="font-size: 30px;">EUR</span></div>
                      <p class="mt-3 text-muted">{{$t('wallet.view.action.make_deposit.hint', limits)}}</p>
                        <p class="mt-3 alert alert-warning">{{$t('wallet.view.action.make_deposit.warning')}}</p>
                    </div>

                    <hr/>
                    <b-row>
                        <b-col sm="6">
                            <div class="large-button-selector">
                                <div class="b mb-2" :class="{selected: deposit.payment_method === 'bank_transfer'}" @click="selectPaymentMethod('bank_transfer')">
                                    <b-row class="p-3">
                                        <b-col>
                                            <i class="fad fa-university"></i> {{$t('growing.component.buy_plant.payment.bank_transfer')}}
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="b mb-2" :class="{selected: deposit.payment_method === 'cash_payment'}" @click="selectPaymentMethod('cash_payment')">
                                    <b-row class="p-3">
                                        <b-col>
                                            <i class="fad fa-money-bill-wave mr-2"></i>{{ $t('growing.component.buy_plant.payment.cash_payment') }}
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </b-col>
                        <b-col sm="6">
                            <div class="b p-2" v-if="address">
                                <p class="font-weight-bold mb-4">{{$t('growing.component.buy_plant.overview.client_info')}}</p>
                                <p class="mb-1" v-if="address.type === 'company'">{{ address.company }}</p>
                                <p class="mb-1">{{ address.firstname }} {{ address.lastname }}</p>
                                <p class="mb-1">{{ address.street }} {{ address.house_number }}</p>
                                <p class="mb-1">{{ address.zip }} {{ address.town }}</p>
                                <p class="mb-1">{{ address.country }}</p>
                                <button @click="edit = true" class="btn btn-secondary">Edit</button>
                            </div>
                        </b-col>
                    </b-row>

                    <hr/>

                    <address-form v-if="edit" ref="addressForm" entity-slot="residency" :confirm-button="false" v-on:address-filled="addressFilled"/>

                    <b-row v-show="!edit">
                        <b-col cols="12" class="text-right mt-1">
                            <button @click="requestDeposit()" class="btn btn-info">{{ $t('wallet.view.action.make_deposit.button.request') }}</button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>

    </ContentWrapper>
</template>

<script>
    import AddressForm from '@/components/Forms/AddressForm.vue';
    import PaymentGateway from '../../services/PaymentGateway.service'

    export default {
        components: {
            AddressForm
        },
        data() {
            return {
                deposit: {
                    amount: 0,
                    payment_method: 'bank_transfer'
                },
                address: null,
                edit: false,
                loading: true,
                limits: {
                    min: 0,
                    max: 0
                }
            }
        },
        mounted() {
            this.$api.get('wallet/deposit-limits').then((response) => {
              this.limits = response.data;

              if (this.limits.max === 0) {
                this.$swal.fire({
                  title: 'Deposit not available',
                  text: 'Your balance is to high for more deposits!'
                })

                this.$router.push({name: 'Dashboard'});
              } else {
                this.$api.get('user/address').then((response) => {
                  this.address = response.data;
                  this.loading = false;
                });

                if (this.$route.query.amount) {
                    this.deposit.amount = this.$route.query.amount;
                } else {
                    this.deposit.amount = Math.ceil(this.limits.max);
                }
              }
            });
        },
        methods: {
            validateAmount() {
                let amount = this.deposit.amount;

                if(amount < this.limits.min) {
                    this.deposit.amount = this.limits.min;
                } else if (amount > this.limits.max) {
                  this.deposit.amount = this.limits.max;
                }

                this.deposit.amount = Math.ceil(this.deposit.amount);
            },
            selectPaymentMethod(payment_method) {
                this.deposit.payment_method = payment_method;
            },
            addressFilled() {
                this.loading = true;
                this.$api.get('user/address').then((response) => {
                    this.loading = false;
                    this.edit = false;
                    this.address = response.data;
                });
            },
            requestDeposit() {
                this.loading = true;
                PaymentGateway.createOrder(3, this.deposit.amount, this.deposit.payment_method);
            }
        },
    }
</script>